import { client } from '../../ApolloClient';
import { QueryOptions } from '@apollo/client';
import DiscoverQueries from '../../graphql/DiscoverQueries';
import VizQueries from '../../graphql/VizGql';
import LoginActions from './LoginActions';
import _, { isNil, isObject } from 'lodash';
import { ChartSpecs } from '../../../discovery/ChartSpecs';
import MainActions from './MainActions';
import { Hierarchy, Viz } from '../../../discovery/VizUtil';
import URLs from '../../Urls';
import Const from './ActionConst';
import { IChartShelf, ShelfTypes } from '../../../discovery/interfaces';
import {
  NULL_DISPLAY,
  NULL_TOKEN,
  getFrontendUrl,
  Types,
  getDynamicValues,
  AllowedDashletDrillLinkDynamicFields,
  ShelfName,
  USE_FISCAL_REPORTING,
  getDashboardFilters,
  ROUTER_DIRS,
  VIZ_OPTION_IDS,
} from '../../Constants';
import ColorManager from '../../../common/d3/ColorManager';
import DateFilter from '../../../discovery/filter/exports/DateFilter';
import uuid from 'uuid';
import { messages } from '../../../i18n';
import {
  IDiscovery,
  IViz,
  IFocusedDataPoint,
  IFocusedLineData,
  ICollectDetailInfoArgs,
  IVizOptions,
  StringBool,
  IBaseChartSpec,
} from '../../../discovery/interfaces';
import { ILegendDatum } from '../../../discovery/charts/viz-legend';
import { IDrillContext } from '../../../discovery/viz-redirect';
import {
  getSelectedSlicers,
  getActiveSlicers,
  getSelectedRuntimeFilters,
  getLinkToReport,
} from '../../../common/redux/selectors/viz-selectors';
import {
  FilterDialogTypes,
  IAnyAttribute,
  IAppliedFilters,
  IDataset,
  IFilter,
} from '../../../datasets/interfaces';

import { getMangoDarkMode } from '../selectors/main-selector-hooks';
import JSON5 from 'json5';
import { PendoClient } from '../../PendoClient';
import { IVizQueryExecuteQueryResults } from '../../graphql/viz-query.interfaces';
import { IDashletFilter } from '../../utilities/sugar-filter-converter/sugar-filter-converter.interfaces';
import { shortid } from '../../utilities/shortid-adapter';

export interface INewVizParams {
  dataset: Partial<IDataset> | IDataset;
  chartSpec?: IBaseChartSpec;
  measures?: IAnyAttribute[];
  axis?: IAnyAttribute[];
  groups?: IAnyAttribute[];
  filters?: {
    [key: string]: IFilter;
  };
  calcFields?: string;
  sortBy?: {
    direction: 'asc' | 'dsc';
    field: IAnyAttribute;
    from: 'start' | 'end';
  };
  options?: Partial<IVizOptions>;
  slicers?: IAnyAttribute[];
}

export interface IVizQueryParams {
  id: string;
  sourceName: string;
  monitorEventId: string;
  variables?: any;
  queryId: string;
  replaceNulls?: boolean;
  secondaryQueryVariables?: any;
  skipAnalyze?: boolean;
}

export const openDiscoveryAction = (action, id) => {
  return dispatch => {
    dispatch(openDiscoveryActionPreProcess(action, id));
    dispatch(openDiscoveryActionProcess(action, id));
  };
};
const openDiscoveryActionPreProcess = (_action, id) => {
  return (_dispatch, getState) => {
    try {
      const discover = getState().discover.openDiscoveries[id]?.present;
      const customColors =
        _.get(discover, `viz.options.${VIZ_OPTION_IDS.customColors}`) ||
        _.find(discover?.options, { key: VIZ_OPTION_IDS.customColors })
          ?.value ||
        {};
      const customColorsObj = _.isString(customColors)
        ? JSON.parse(customColors)
        : customColors;
      if (!_.isEmpty(customColorsObj)) {
        ColorManager.setCustomColors(id, customColorsObj);
      }
    } catch (e) {
      console.log('Failed to preprocess discovery', e);
    }
  };
};
const openDiscoveryActionProcess = (action, id) => {
  return { ...action, discoveryId: id };
};
const Actions = {
  setPanelDetail(id, value) {
    return {
      type: Const.Discover.SET_PANEL_DETAIL,
      detail: value,
      discoveryId: id,
    };
  },
  setLiveQueryForViz({ id, value }) {
    return dispatch => {
      dispatch({
        type: Const.Discover.SET_LIVE_QUERY,
        discoveryId: id,
        value,
      });
    };
  },
  setSettingForViz({ id, setting, value }) {
    return dispatch => {
      // remove non-undoable state
      if (setting === VIZ_OPTION_IDS.linkToReport && _.isEmpty(value)) {
        dispatch({
          type: Const.Discover.SET_FOCUSED_VIZ_DATA_POINTS,
          discoveryId: id,
          pointData: [],
        });
      }
      dispatch({
        type: Const.Discover.SET_SETTING_FOR_VIZ,
        discoveryId: id,
        setting,
        value,
      });
    };
  },
  setTagsForViz(id, tags) {
    return {
      type: Const.Discover.SET_DISCOVERY_TAGS,
      discoveryId: id,
      tags,
    };
  },
  postWindowOpenToFocusDrawer(queryParams, messageBody) {
    //Send message and data to MFE frame to trigger focus drawer
    window.top.postMessage(
      {
        type: 'SELECT_ID_FOCUS',
        messageBody,
        queryParams,
        dashletDiscoveryId: messageBody?.viz?.id,
        name: messageBody?.viz?.name,
      },
      window.location.origin,
    );
  },
  postWindowOpenReport(queryParams, messageBody) {
    const id = uuid();
    const isMangoDarkMode = getMangoDarkMode();
    const appearance = _.isBoolean(isMangoDarkMode)
      ? isMangoDarkMode
        ? 'dark'
        : 'light'
      : undefined;
    const frontendUrl = getFrontendUrl();
    const w = window.open(
      `${URLs.joinUrls(frontendUrl, 'reportLink')}${URLs.stringifyQueryParams({
        instanceUrl: window.location.origin,
        drillContextId: id,
        appearance,
        ...queryParams,
      })}`,
      '_blank',
    );
    window.addEventListener(
      'message',
      m => {
        if (frontendUrl?.startsWith(m.origin) && m.data === id) {
          w.postMessage({ id, ...messageBody, appearance }, frontendUrl);
        }
      },
      true,
    );
  },
  setDrillContext(drillContext: IDrillContext) {
    return dispatch => {
      dispatch({
        type: Const.Discover.SET_REPORT_DETAIL_INFO,
        drillContext,
      });
    };
  },
  /**
   * Opens a report with provided info
   * @param drillContext info potentially used to update the opened report
   * @param history most likely object from withRouter
   */
  openReportLink(drillContext: IDrillContext, history?) {
    return (dispatch, getState) => {
      const {
        main: { tenantId, tenantIdmId },
        discover: { displayDiscovery, openDiscoveries },
        dashlet: { isDashletMode, hasSideDrawerDrill = false },
      } = getState();
      const current = _.get(openDiscoveries, [displayDiscovery, 'present']);
      const fiscalCalendarInfo = Viz.getFiscalCalendarInfo(current?.viz);
      const slicers = getActiveSlicers(getState(), {});
      const slicerSelections = getSelectedSlicers(getState(), {});
      const linkToReport = getLinkToReport(getState(), {});

      const dynamicValues = _.pick(
        getDynamicValues(),
        AllowedDashletDrillLinkDynamicFields,
      );

      drillContext = {
        ...drillContext,
        linkToReport,
        fiscalCalendarInfo,
        slicers,
        slicerSelections,
        dynamicValues,
        isFromDrillEvent: true,
      };

      // Send this out to the application state -- for some reason IOS Safari
      // won't handle the route state being sent, which breaks mobile drill
      // linking (https://sugarcrm.atlassian.net/browse/DSC-5288)
      dispatch(Actions.setDrillContext(drillContext));

      if (!isDashletMode) {
        history.push(ROUTER_DIRS.REPORT_LINK, {
          drillContext,
        });
        return;
      }

      const runtimeFilters = getSelectedRuntimeFilters(getState(), {});

      const dashboardFilters: IDashletFilter = getDashboardFilters();

      drillContext = {
        ...drillContext,
        runtimeFilters,
        dashboardFilters,
      };

      dispatch(Actions.setDrillContext(drillContext));

      const linkStrategy = _.get(
        current,
        `viz.options.${VIZ_OPTION_IDS.linkStrategy}`,
        'update',
      );
      const vizId = current?.viz.id;

      const queryParams = {
        tenant_id: tenantId,
        tenant_idm_id: tenantIdmId,
      };
      const messageBody = {
        drillContext,
        linkStrategy,
        viz: {
          id: vizId,
          name: current?.viz?.name,
        },
      };

      if (hasSideDrawerDrill) {
        Actions.postWindowOpenToFocusDrawer(queryParams, messageBody);
        return;
      }

      Actions.postWindowOpenReport(queryParams, messageBody);
      return;
    };
  },
  // runs on full app after drill linking from dashlet
  updateDrillContext(drillContextId: string, instanceUrl = getFrontendUrl()) {
    return dispatch => {
      window.opener?.postMessage(drillContextId, instanceUrl); // post message to MFE for more report detail
      dispatch({
        type: Const.Discover.BEGIN_REPORT_DETAIL_UPDATE,
      });
      const handler = message => {
        if (message.origin !== instanceUrl) {
          return;
        }

        const {
          data: { drillContext, id, linkStrategy, appearance },
        } = message;
        if (id === drillContextId) {
          dispatch(MainActions.setUserAppearanceOverride(appearance));
          dispatch({
            type: Const.Discover.SET_REPORT_DETAIL_INFO,
            drillContext,
            linkStrategy,
          });
          window.removeEventListener('message', handler);
        }
        return;
      };
      window.addEventListener('message', handler);
    };
  },
  setCustomFormatToggle(
    id: string,
    checked: boolean,
    name: string,
    options = null,
  ) {
    return {
      type: Const.Discover.SET_SHOW_CUSTOM_FORMAT_OPTION,
      discoveryId: id,
      checked,
      name,
      options,
    };
  },
  setFunnelStageVisibilityOptions(discoveryId, stageOptions) {
    return {
      type: Const.Discover.SET_FUNNEL_STAGE_VISIBILITY_OPTIONS,
      discoveryId,
      stageOptions,
    };
  },
  /**
   * Show the panel on the left side
   * @param id
   * @param value one of [layout, bars, settings, format]
   */
  setConfigPanelDetail(
    id: string,
    value: 'layout' | 'bars' | 'settings' | 'format',
  ) {
    return {
      type: Const.Discover.SET_CONFIG_PANEL_DETAIL,
      detail: value,
      discoveryId: id,
    };
  },
  setShowAddMonitorDialog(value, viz?) {
    return {
      type: Const.Discover.SET_SHOW_ADD_MONITOR_DIALOG,
      show: value,
      viz,
    };
  },
  setShowCompMonitorDialog(value) {
    return {
      type: Const.Discover.SET_SHOW_ADD_COMP_MONITOR,
      show: value,
    };
  },
  openDiscovery(discovery) {
    return (dispatch, getState) => {
      // open a discovery
      const disc = {
        ...discovery,
        panelDetail: null,
        showPanel: true,
        dirty: false,
      };
      // default order for tabs
      const openDiscoveriesTabList = [
        ...getState().discover.openDiscoveriesTabList,
      ];

      let _tabIndex = _.findIndex(openDiscoveriesTabList, { id: disc?.id });

      if (_tabIndex < 0) {
        _tabIndex = openDiscoveriesTabList.length;
      }

      dispatch(MainActions.setActiveTab('discover'));

      dispatch({
        type: Const.Discover.ADD_OPEN_DISCOVERY,
        discoveryId: disc.id,
        tabIndex: _tabIndex,
      });

      dispatch({
        type: Const.Discover.SET_OPEN_DISCOVERY_STATE,
        discovery: disc,
        discoveryId: disc.id,
      });

      dispatch(this.setDisplayDiscovery(disc.id));
    };
  },
  openVisualization(discovery) {
    return (dispatch, getState) => {
      try {
        const {
          main: { appUrl, tenantId },
          account: {
            currentUser: { id: userSrn },
          },
        } = getState();
        PendoClient.track(discovery, tenantId, appUrl, userSrn);
      } catch (e) {
        console.warn('could not track open event', e);
      }
      dispatch(this.openDiscovery({ ...discovery, loading: true }));
      dispatch(this.updateVisualizationData(discovery.id));
    };
  },
  setVizIsEmpty(isEmpty) {
    return dispatch =>
      dispatch({ type: Const.Discover.SET_VIZ_IS_EMPTY, isEmpty });
  },
  updateVisualizationData(discoveryId) {
    return dispatch => {
      client
        .query({
          query: VizQueries.GetVisualization,
          variables: { id: discoveryId },
          fetchPolicy: 'network-only',
        })
        .then(data => {
          dispatch(
            openDiscoveryAction(
              {
                type: Const.Discover.UPDATE_DISCOVERY_WITH_VIZ,
                visualization: data.data.visualization,
                discoveryId,
              },
              discoveryId,
            ),
          );
        })
        .catch(e => {
          console.error(e);
          dispatch(
            openDiscoveryAction(
              {
                type: Const.Discover.UPDATE_DISCOVERY_WITH_ERROR,
                error: 'Failed to retrieve report from server',
              },
              discoveryId,
            ),
          );
        });
    };
  },
  closeDiscovery(id, args: any = {}) {
    return (dispatch, getState) => {
      /*
      args for this method:
      - force will not present a confirm message to user
       */
      const { force = false } = args;
      const od = getState().discover.openDiscoveries;
      const tabList = getState().discover.openDiscoveriesTabList;
      let { prevDisplayDiscovery } = getState().discover;
      let { displayDiscovery } = getState().discover;

      // Check for unsaved changes
      if (
        !_.isUndefined(od[id]) &&
        od[id].present.dirty &&
        od[id].present.canUpdate &&
        !force
      ) {
        dispatch({
          type: Const.Discover.SET_SHOW_SAVE_DIALOG,
          show: true,
          closeDialogAfterSave: true,
        });

        // Set focus to discovery for save prompt and cancel close
        if (displayDiscovery !== id) {
          dispatch(this.setDisplayDiscovery(id));
        }

        return;
      }

      const remainingOpen = _.pickBy(od, element => element.present.id !== id);
      const odTabList = _.without(tabList, id);

      if (_.isEmpty(remainingOpen)) {
        prevDisplayDiscovery = null;
        displayDiscovery = null;
      } else if (id === displayDiscovery) {
        displayDiscovery = _.includes(odTabList, prevDisplayDiscovery)
          ? prevDisplayDiscovery
          : odTabList[odTabList.length - 1];
      }

      dispatch({ type: Const.Discover.SET_SAVE_ACTIVE, value: false });
      dispatch({ type: Const.Discover.SET_SHOW_SAVE_DIALOG, show: false });
      dispatch({
        type: Const.Discover.CLOSE_OPEN_DISCOVERY,
        openDiscoveries: remainingOpen,
        openDiscoveriesTabList: odTabList,
        prevDisplayDiscovery,
        displayDiscovery,
      });

      if (displayDiscovery) {
        URLs.goTo(ROUTER_DIRS.OPEN_VIZ(displayDiscovery));
      } else {
        URLs.goTo(ROUTER_DIRS.LIBRARY);
      }
    };
  },
  closeDeletedDiscovery(id) {
    return (dispatch, getState) => {
      const { openDiscoveries } = getState().discover;
      let odTabList = getState().discover.openDiscoveriesTabList;
      let { prevDisplayDiscovery } = getState().discover;
      let { displayDiscovery } = getState().discover;
      const removed = _.pickBy(openDiscoveries, o => o.present.id !== id);
      odTabList = _.without(odTabList, id);

      if (_.isEmpty(removed)) {
        prevDisplayDiscovery = null;
        displayDiscovery = null;
      } else if (
        id === displayDiscovery &&
        _.includes(odTabList, prevDisplayDiscovery)
      ) {
        displayDiscovery = prevDisplayDiscovery;
      } else {
        displayDiscovery = odTabList[odTabList.length - 1];
      }

      dispatch({
        type: Const.Discover.CLOSE_OPEN_DELETED_DISCOVERY,
        openDiscoveries: removed,
        openDiscoveriesTabList: odTabList,
        prevDisplayDiscovery,
        displayDiscovery,
      });
    };
  },
  setDisplayDiscovery(id, state = {}) {
    return (dispatch, getState) => {
      const {
        discover: { displayDiscovery: prevDisplayDiscovery, openDiscoveries },
        dashlet: { isDashletMode },
      } = getState();
      dispatch({
        type: Const.Discover.SET_DISPLAY_DISCOVERY,
        displayDiscovery: id,
        prevDisplayDiscovery,
        discoveryAlreadyRendered:
          id !== prevDisplayDiscovery &&
          !_.isEmpty(id) &&
          !_.isEmpty(prevDisplayDiscovery) &&
          !_.isEmpty(openDiscoveries[id]) &&
          !_.isEmpty(openDiscoveries[id]?.history?.history),
      });

      // maintain the current position in tab list order
      const tabList = getState().discover.openDiscoveriesTabList;
      const tabIndex = _.indexOf(tabList, id);
      if (!isDashletMode) {
        URLs.goTo(ROUTER_DIRS.OPEN_VIZ(id), {
          ...state,
          openDiscoveryTabIndex: tabIndex,
        });
      }
    };
  },
  cancelDiscoverySave() {
    return dispatch => {
      dispatch({ type: Const.Discover.SET_SAVE_ACTIVE, value: false });
      dispatch({ type: Const.Discover.SET_SHOW_SAVE_DIALOG, show: false });
    };
  },
  cancelDiscoverySaveAs() {
    return dispatch => {
      dispatch({ type: Const.Discover.SET_SAVE_ACTIVE, value: false });
      dispatch({ type: Const.Discover.SET_SHOW_SAVE_AS_DIALOG, show: false });
    };
  },
  executeQuery(json: string) {
    return dispatch => {
      client
        .query({
          query: DiscoverQueries.ExecuteQuery,
          variables: { query: JSON.parse(json).query },
          fetchPolicy: 'network-only',
        })
        .then(data => {
          dispatch({
            type: Const.Discover.POST_QUERY_FULFILLED,
            queryResults: data.data.executeQuery,
          });
        })
        .catch(
          LoginActions.onFailure.bind(
            this,
            dispatch,
            (_dispatch: any, data: any) => {
              console.log(`failed to execute query: ${data}`);
            },
          ),
        );
    };
  },
  newVisualization(newViz: INewVizParams) {
    const {
      dataset,
      chartSpec = ChartSpecs.bar,
      measures = [],
      axis = [],
      groups = [],
      filters = {},
      calcFields = [],
      sortBy,
      options = {},
      slicers = [],
    } = newViz;
    return (dispatch, getState) => {
      const {
        discover: { displayDiscovery, openDiscoveries },
      } = getState();

      const type = 'VISUALIZATION';
      const untitledPrefix = messages.untitled;
      const panelToShow = 'layout';
      const newVizId = `newViz-${shortid.generate()}`;
      const openViz = _.get(openDiscoveries, [displayDiscovery, 'present']);
      const { useFiscalCalendar } = Viz.getFiscalCalendarInfo({
        dataset,
        options: [
          {
            key: USE_FISCAL_REPORTING,
            value: 'true',
          },
        ],
        ...openViz?.viz,
      });
      // get the next untitled index for the name
      const unSavedVizs = _(openDiscoveries)
        .values()
        .filter(
          d =>
            d.present.discoveryType === type &&
            _.startsWith(d.present.name, untitledPrefix),
        )
        .value();
      let nextIndex = 1;
      if (!_.isEmpty(unSavedVizs)) {
        _.forEach(unSavedVizs, d => {
          const untitledMatch = _.get(d, 'present.name').match(/(\d+)$/);
          if (!untitledMatch) {
            return false;
          }
          const idx = parseInt(_.head(untitledMatch), 10);
          nextIndex = Math.max(idx ? idx + 1 : 0, nextIndex);
        });
      }

      // find any timestamp fields in the dataset and default them to have a default time hierarchy
      const timeHierarchies = dataset.attributes.reduce((fields, current) => {
        const field = { ...current };
        if (field.attributeType === Types.TIMESTAMP) {
          // default any timestamp fields to also include a default time hierarchy
          fields[current.name] = Hierarchy.createTimeCalcFields(field);
        }
        return fields;
      }, {});

      // get default filters
      const defaultFilters = {};

      const snapshotDateField = _.find(
        dataset.attributes,
        a => _.lowerCase(a.name) === _.lowerCase('Snapshot Date'),
      );
      if (!_.isNil(snapshotDateField)) {
        defaultFilters[snapshotDateField.name] = DateFilter(
          snapshotDateField,
          ['1', 'DAYS', 'false'],
          'this',
        );
      }
      const datasetAnnotations = _.get(dataset, 'annotations', []);
      const datasetFiltersValue = _.find(datasetAnnotations, {
        key: 'FILTERS',
      })?.value;
      const datasetFilters = _.isString(datasetFiltersValue)
        ? JSON5.parse(datasetFiltersValue)
        : datasetFiltersValue;
      _.forEach(datasetFilters, (filter, key) => {
        if (_.some(dataset.attributes, { name: key })) {
          defaultFilters[key] = filter;
        }
      });

      const viz: Partial<IViz> = {
        chartType: chartSpec.id,
        dataset: dataset as IDataset,
        options: {
          [VIZ_OPTION_IDS.timeHierarchies]: JSON.stringify(timeHierarchies),
          [VIZ_OPTION_IDS.calcFields]: JSON.stringify(calcFields),
          [VIZ_OPTION_IDS.filters]: JSON.stringify(
            _.isEmpty(filters) ? defaultFilters : filters,
          ),
          [VIZ_OPTION_IDS.showPanel]: true,
          [VIZ_OPTION_IDS.panelDetail]: null,
          [VIZ_OPTION_IDS.configPanelDetail]: panelToShow,
          [VIZ_OPTION_IDS.alignYAxesAtZero]: 'true',
          [VIZ_OPTION_IDS.showLegendPanel]: 'true',
          [VIZ_OPTION_IDS.showFiltersPanel]: 'true',
          [VIZ_OPTION_IDS.showDataLabels]: 'true',
          [VIZ_OPTION_IDS.useLiveQuery]: 'false',
          [VIZ_OPTION_IDS.useFiscalCalendar]: String(
            useFiscalCalendar,
          ) as StringBool,
          ...options,
        } as IVizOptions,
        layoutMapping: {},
      };

      if (!_.isEmpty(slicers)) {
        viz.layout = {
          COLUMNS: [],
          ROWS: [],
          VALUES: [],
          SLICER: slicers,
        };
      }

      chartSpec.copyLayoutToViz(viz);
      // add measures
      const measureShelves = _(chartSpec.shelves)
        .values()
        .filter(_.matches({ shelfType: ShelfTypes.MEASURE }))
        .value();
      if (measureShelves.length > 0) {
        viz.layout[measureShelves[0].id] = [...measures];
      }
      // add selections
      const selectionShelves = _(chartSpec.shelves)
        .values()
        .filter(_.matches({ shelfType: ShelfTypes.SELECTION }))
        .value();
      if (selectionShelves.length > 0) {
        // groups
        viz.layout[selectionShelves[0].id] = [...groups];
      }
      if (selectionShelves.length > 1) {
        // axis
        viz.layout[selectionShelves[1].id] = [...axis];
      }

      const newDiscovery: Partial<IDiscovery> = {
        id: newVizId,
        name: `${untitledPrefix} ${nextIndex}`,
        dataset: dataset as IDataset,
        dirty:
          _.flatten(_.values(viz.layout)).length !== 0 ||
          !_.isEmpty(filters) ||
          !_.isEmpty(calcFields),
        canUpdate: true,
        discoveryType: type,
        viz: viz as IViz,
      };

      dispatch({
        type: Const.Discover.ADD_OPEN_DISCOVERY,
        discoveryId: newDiscovery.id,
      });
      dispatch({
        type: Const.Discover.SET_OPEN_DISCOVERY_STATE,
        discovery: newDiscovery,
        discoveryId: newDiscovery.id,
      });
      dispatch(this.setDisplayDiscovery(newDiscovery.id));
      if (!_.isEmpty(sortBy)) {
        const { field, direction } = sortBy ?? {
          field: undefined,
          direction: undefined,
        };
        if (_.isEqual(chartSpec, ChartSpecs.pivot)) {
          dispatch({
            type: Const.Discover.SET_PIVOT_SORTING,
            discoveryId: newVizId,
            sort: sortBy,
          });
        } else {
          let shelf;
          let shelfFields;
          _.forEach(viz.layout, (fields, shelfName) => {
            const index = _.findIndex(fields, _.matches({ name: field.name }));
            if (index !== -1) {
              shelf = chartSpec.shelves[shelfName];
              shelfFields = fields;
            }
          });
          dispatch({
            type: Const.Discover.SORT_VIZ_DATA,
            field,
            direction,
            shelf,
            shelfFields,
            discoveryId: newVizId,
          });
        }
      }
    };
  },
  setSaveActive(value) {
    return { type: Const.Discover.SET_SAVE_ACTIVE, value };
  },
  setSaveError(msg: string) {
    return { type: Const.Discover.SET_SAVE_ERROR, msg };
  },
  setShowSaveDialog(show) {
    return { type: Const.Discover.SET_SHOW_SAVE_DIALOG, show };
  },
  setShowVersionDialog(show: boolean) {
    return { type: Const.Discover.SET_SHOW_VERSION_DIALOG, show };
  },
  setShowSaveAsDialog(show) {
    return { type: Const.Discover.SET_SHOW_SAVE_AS_DIALOG, show };
  },
  setDiscoveryDirty(id, dirty) {
    return {
      type: Const.Discover.SET_DISCOVERY_DIRTY,
      dirty,
      discoveryId: id,
    };
  },
  clearQueryResults(id) {
    return {
      type: Const.Discover.CLEAR_VIZ_QUERY,
      discoveryId: id,
    };
  },
  setDiscoverySaveCheckpoint(id, updatedOn, revisions, saveCheckpointIndex?) {
    return {
      type: Const.Discover.SET_SAVE_CHECKPOINT,
      discoveryId: id,
      updatedOn,
      index: saveCheckpointIndex,
      revisions,
    };
  },
  updateVizWithId(discoveryId, newId, creatorName, creator) {
    return {
      type: Const.Discover.SET_VIZ_ID,
      discoveryId,
      id: newId,
      creatorName,
      creator,
    };
  },
  changeDiscoveryName(id, name) {
    return {
      type: Const.Discover.CHANGE_DISCOVERY_NAME,
      discoveryId: id,
      name,
    };
  },

  addFieldToVisualization(
    discoveryId: string,
    field: IAnyAttribute,
    shelf,
    index?: number,
    reduxTransactionId?: string,
  ) {
    return dispatch => {
      dispatch({
        type: Const.Discover.ADD_FIELD_TO_VIZ,
        field,
        shelf,
        index,
        discoveryId,
        reduxTransactionId,
      });
    };
  },
  removeFieldsByName(id, fieldNames, reduxTransactionId) {
    return (dispatch, getState) => {
      const od = getState().discover.openDiscoveries;
      const layout = _.get(od, `${id}.present.viz.layout`, {});
      const txId = reduxTransactionId || shortid.generate();
      fieldNames.forEach(fieldName => {
        const shelf = Viz.findShelfContainingField(layout, fieldName);
        if (shelf) {
          const field = _.find(layout[shelf]).find({ name: fieldName });
          if (field) {
            dispatch(this.removeFieldFromVisualization(id, field, shelf, txId));
          }
        }
      });
    };
  },
  removeFieldFromVisualization(id, field, shelf, reduxTransactionId?) {
    return dispatch => {
      dispatch({
        type: Const.Discover.REMOVE_FIELD_FROM_VIZ,
        field,
        shelf,
        discoveryId: id,
        reduxTransactionId,
      });
      dispatch({
        type: Const.Discover.CLEAR_VIZ_SORTING,
        discoveryId: id,
        field,
        shelf,
      });
    };
  },
  moveFieldToAnotherShelf(
    id,
    field,
    fromShelf,
    toShelf,
    index?: number,
    reduxTransactionId?: string,
  ) {
    return dispatch => {
      dispatch({
        type: Const.Discover.MOVE_FIELD_TO_ANOTHER_SHELF,
        field,
        fromShelf,
        toShelf,
        discoveryId: id,
        index,
        reduxTransactionId,
      });
      dispatch({
        type: Const.Discover.CLEAR_VIZ_SORTING,
        discoveryId: id,
        field,
        shelf: toShelf,
      });
    };
  },

  changeVizType(id, type) {
    return {
      type: Const.Discover.CHANGE_VIZ_TYPE,
      chartType: type,
      discoveryId: id,
    };
  },
  reorderVizShelf(id, shelfId, shelfFields) {
    return {
      type: Const.Discover.REORDER_VIZ_SHELF,
      shelfId,
      shelfFields,
      discoveryId: id,
    };
  },
  sortVizData({ field, shelf, shelfFields, direction, discoveryId }) {
    return {
      type: Const.Discover.SORT_VIZ_DATA,
      field,
      direction,
      shelf,
      shelfFields,
      discoveryId,
    };
  },
  redoDiscovery(id: string) {
    return dispatch => {
      dispatch({ type: 'REDO', discoveryId: id });
      dispatch(openDiscoveryActionPreProcess({}, id));
    };
  },
  undoDiscovery(id: string) {
    return dispatch => {
      dispatch({ type: 'UNDO', discoveryId: id });
      dispatch(openDiscoveryActionPreProcess({}, id));
    };
  },
  setVizAccess(id, access) {
    return { type: Const.Discover.SET_VIZ_ACCESS, discoveryId: id, access };
  },
  focusAnomaly(id, anomaly, dataFrequency, focusWindowPadding) {
    // Set default monitor data frequency
    if (_.isUndefined(dataFrequency)) {
      // Default to one minute
      dataFrequency = 1000 * 60;
    }
    // Set default window padding
    if (_.isUndefined(focusWindowPadding)) {
      focusWindowPadding = 5;
    }
    return (dispatch: any) => {
      const minDelta = Math.round(dataFrequency) * focusWindowPadding;
      const delta = Math.max(
        anomaly.endTimestamp - anomaly.startTimestamp,
        minDelta,
      );
      const low = new Date(anomaly.startTimestamp - delta);
      const high = new Date(anomaly.endTimestamp + delta);
      dispatch({
        type: Const.Discover.CHANGE_DISPLAY_RANGE,
        displayDateRange: [low, high],
        discoveryId: id,
      });
      dispatch({
        type: Const.Discover.CHANGE_RANGE_SELECTION,
        rangeSelection: 'custom',
        discoveryId: id,
      });
    };
  },
  setVizTrashDropTarget(targetId: string) {
    return {
      type: Const.Discover.SET_VIZ_TRASH_DROP_TARGET,
      dndTrashTargetId: targetId,
    };
  },

  setVizLegendData(id: string, legendData: ILegendDatum[]) {
    return {
      type: Const.Discover.SET_VIZ_LEGEND_DATA,
      discoveryId: id,
      legendData,
    };
  },
  setToolTipData(id: string, tooltipData: any) {
    return {
      type: Const.Discover.SET_TOOLTIP_DATA,
      discoveryId: id,
      tooltipData,
    };
  },
  showFieldFilterDialog(field: string) {
    return {
      type: Const.Discover.SET_SHOW_VIZ_FILTER_DIALOG,
      field,
      show: FilterDialogTypes.STANDARD,
    };
  },
  hideFieldFilterDialog() {
    return {
      type: Const.Discover.SET_SHOW_VIZ_FILTER_DIALOG,
      field: null,
      show: false,
    };
  },
  showFieldFilterAggregateDialog(field: string) {
    return {
      type: Const.Discover.SET_SHOW_VIZ_FILTER_DIALOG,
      field,
      show: FilterDialogTypes.AGGREGATE,
    };
  },
  applyFieldFilter(id: string, filter: IFilter, reduxTransactionId?: string) {
    // This should be called when the Apply button is hit in the Filter Dialog. It should be undo-able
    return {
      type: Const.Discover.APPLY_VIZ_FIELD_FILTER,
      discoveryId: id,
      filter,
      reduxTransactionId,
    };
  },
  removeFieldFilter(id: string, filter: IFilter) {
    return {
      type: Const.Discover.REMOVE_VIZ_FIELD_FILTER,
      discoveryId: id,
      filter,
    };
  },
  applyVizState({
    id,
    filters = {},
    prependFilters = false,
    removeFiltersOnFields = [],
    calcs = [],
    metrics = [],
    toShelves = {},
    slicers = [],
    slicerSelections = [],
    useFiscalCalendar,
  }: {
    id: string;
    filters?: IAppliedFilters;
    prependFilters?: boolean;
    removeFiltersOnFields?: string[];
    calcs?;
    metrics?;
    toShelves?;
    slicers?;
    slicerSelections?;
    useFiscalCalendar?: boolean;
  }) {
    return {
      type: Const.Discover.APPLY_VIZ_STATE,
      discoveryId: id,
      filters,
      prependFilters,
      removeFiltersOnFields,
      calcs,
      metrics,
      toShelves,
      slicers,
      slicerSelections,
      useFiscalCalendar,
    };
  },
  applyMonitorEventId(id, monitorEventId) {
    return {
      type: Const.Discover.APPLY_MONITOR_EVENT_ID,
      discoveryId: id,
      monitorEventId,
    };
  },
  setActiveFieldFilter(filter) {
    // This should be called to update the current filter as the user makes changes in the Filter Dialog. These actions should not be undo-able
    return { type: Const.Discover.SET_ACTIVE_VIZ_FIELD_FILTER, filter };
  },
  setShowFieldCalcDialog(show, field?: IAnyAttribute) {
    return {
      type: Const.Discover.SET_SHOW_VIZ_CALC_DIALOG,
      show,
      field,
    };
  },
  saveCalcField(id, field, previousName, transactionId) {
    return {
      type: Const.Discover.SAVE_CALC_FIELD,
      discoveryId: id,
      field,
      previousName,
      reduxTransactionId: transactionId,
    };
  },
  deleteCalcFields(id, fields, transactionId?: string) {
    return {
      type: Const.Discover.DELETE_CALC_FIELDS,
      discoveryId: id,
      fields,
      reduxTransactionId: transactionId,
    };
  },
  updateFieldInViz(
    id,
    field,
    fieldMeta = {},
    previousFieldName,
    transactionId,
  ) {
    return {
      type: Const.Discover.UPDATE_FIELD_IN_VIZ,
      discoveryId: id,
      field,
      fieldMeta,
      previousName: previousFieldName,
      reduxTransactionId: transactionId,
    };
  },
  setFocusedVizData(id, data) {
    return (dispatch, getState) => {
      const {
        main: { isMobile, metaDown, controlDown },
      } = getState();
      const multiSelectEnabled = metaDown || controlDown || isMobile;
      dispatch({
        type: Const.Discover.SET_FOCUSED_VIZ_DATA,
        discoveryId: id,
        data,
        multiSelectEnabled,
      });

      if (isNil(data)) {
        dispatch(Actions.setFocusedDataPoints(id, [], []));
      }

      dispatch(Actions.setDrillContextFromArgs(id));
    };
  },
  setDrillContextFromArgs(discoveryId) {
    return (dispatch, getState) => {
      const open = getState().discover.openDiscoveries;
      const disc = open[discoveryId]?.present;

      if (_.isEmpty(disc)) {
        return;
      }

      const { focusedDataPoints = [], collectDetailInfoArgs = null } = disc;

      if (
        !_.isNil(collectDetailInfoArgs) &&
        _.isFunction(collectDetailInfoArgs?.chartUtilities?.collectDetailInfo)
      ) {
        const {
          data,
          lineData,
          dataItem,
          shelf: shelfId,
        } = collectDetailInfoArgs;

        const drillContext =
          collectDetailInfoArgs.chartUtilities.collectDetailInfo(
            data,
            lineData,
            dataItem,
            shelfId,
            focusedDataPoints,
          );

        dispatch(Actions.setDrillContext(drillContext));
      }
    };
  },
  toggleFocusedDataPoint(
    discoveryId,
    pointData: IFocusedDataPoint,
    lineData?: IFocusedLineData,
    collectDetailInfoArgs?: ICollectDetailInfoArgs,
  ) {
    return dispatch => {
      dispatch({
        type: Const.Discover.TOGGLE_FOCUSED_VIZ_DATA_POINTS,
        discoveryId,
        pointData,
        lineData,
        collectDetailInfoArgs,
      });

      dispatch(Actions.setDrillContextFromArgs(discoveryId));
    };
  },
  setFocusedDataPoints(
    discoveryId,
    pointData: IFocusedDataPoint[],
    lineData?: IFocusedLineData[],
    collectDetailInfoArgs?: ICollectDetailInfoArgs,
  ) {
    return dispatch => {
      dispatch({
        type: Const.Discover.SET_FOCUSED_VIZ_DATA_POINTS,
        discoveryId,
        pointData,
        lineData,
        collectDetailInfoArgs,
      });

      dispatch(Actions.setDrillContextFromArgs(discoveryId));
    };
  },
  showTimeHierarchy(id, field, timeHierarchyFields) {
    return {
      type: Const.Discover.SHOW_TIME_HIERARCHY,
      discoveryId: id,
      field,
      timeHierarchyFields,
    };
  },
  removeTimeHierarchy(id, field) {
    const allHidden = Hierarchy.createTimeCalcFields(
      field,
      Object.values(Hierarchy.TIME_ATTRIBUTES),
    );
    allHidden.forEach(a => {
      a.hidden = true;
    });
    return {
      type: Const.Discover.SHOW_TIME_HIERARCHY,
      discoveryId: id,
      field,
      timeHierarchyFields: allHidden,
    };
  },
  vizQuery({
    id,
    variables,
    queryId,
    replaceNulls = true,
    secondaryQueryVariables,
    skipAnalyze = false,
  }: IVizQueryParams) {
    return (dispatch, getState) => {
      dispatch({
        type: Const.Discover.VIZ_QUERY_LOADING,
        discoveryId: id,
        loading: true,
        queryId,
      });

      const analyzeQueryOptions: QueryOptions = {
        fetchPolicy: 'network-only',
        query: VizQueries.AnalyzeQuery,
        variables,
      };

      // Analyze the query for potential performance issues before we run it
      const analyzePromise = new Promise((resolve, reject) => {
        if (skipAnalyze) {
          // bypass the analyze step
          resolve(true);
        } else {
          client
            .query(analyzeQueryOptions)
            .then(results => {
              const warnings = _.get(results, 'data.analyze', []);
              if (_.isEmpty(warnings)) {
                // no issues, go ahead and run the query
                resolve(true);
              } else {
                // there were issues detected in the query
                reject(warnings);
              }
            })
            .catch(({ message }) => {
              reject([
                {
                  type: 'GraphQL',
                  message,
                },
              ]);
            });
        }
      });

      return analyzePromise
        .then(async () => {
          const od = getState().discover.openDiscoveries;
          const { viz } = od[id].present;
          const modifiedVariablesObj = await postModifyVariables({
            viz,
            variables,
          });

          modifiedVariablesObj.dispatch &&
            dispatch(modifiedVariablesObj.dispatch);
          const modifiedVariables = modifiedVariablesObj.variables;
          const queryOptions: QueryOptions = {
            fetchPolicy: 'network-only',
            query: VizQueries.VizQuery,
            variables: modifiedVariables,
          };

          // keep track of all queries required for this execution so we can wait for all queries to complete
          const promises = [];
          const primaryQueryPromise = client.query(queryOptions);
          promises.push(primaryQueryPromise);

          let modifiedSecondaryVariables = secondaryQueryVariables;
          if (!_.isNil(secondaryQueryVariables)) {
            const modifiedSecondaryVariablesObj = await postModifyVariables({
              viz,
              variables: secondaryQueryVariables,
            });
            modifiedSecondaryVariables =
              modifiedSecondaryVariablesObj.variables;

            const secondaryQueryOptions: QueryOptions = {
              fetchPolicy: 'network-only',
              query: VizQueries.VizQuery,
              variables: modifiedSecondaryVariables,
            };
            const secondaryQueryPromise = client.query(secondaryQueryOptions);
            promises.push(secondaryQueryPromise);
          }

          Promise.all(promises)
            .then(queryData => {
              const scrubbedData = [];

              // Make sure we're the latest query
              const dis = getState().discover.openDiscoveries[id].present;
              if (queryId !== dis.queryId) {
                console.log(
                  'Query returned but is already out-of-date. Results will be ignored',
                );
                return;
              }
              queryData.forEach(unmodifiableData => {
                const data = _.cloneDeep(unmodifiableData);
                if (replaceNulls) {
                  data.data.executeQuery.results = (
                    data.data as IVizQueryExecuteQueryResults
                  ).executeQuery.results.map(row => {
                    return row.map((value: string | number) => {
                      // replace all occurrences of '__NULL__' with '-'
                      return value === NULL_TOKEN ? NULL_DISPLAY : value;
                    });
                  });
                }
                scrubbedData.push(data);
              });

              ColorManager.reset(id);

              dispatch({
                type: Const.Discover.VIZ_QUERY_FINISHED,
                discoveryId: id,
                loading: false,
                data: scrubbedData[0],
                queryId,
                secondaryData:
                  scrubbedData.length > 1 ? scrubbedData[1] : undefined,
              });
              dispatch(openDiscoveryActionPreProcess({}, id));
            })
            .catch(e => {
              dispatch({
                type: Const.Discover.VIZ_QUERY_ERROR,
                discoveryId: id,
                loading: false,
                error: e,
                queryOptions,
                queryId,
              });
            });
        })
        .catch(_queryWarnings => {
          const queryWarnings = Array.isArray(_queryWarnings)
            ? _queryWarnings
            : [_queryWarnings];

          queryWarnings.forEach(warning => {
            console.warn(
              `${warning.type}: ${warning.message}`,
              warning.attributeNames,
            );
          });

          dispatch({
            type: Const.Discover.VIZ_QUERY_WARNING,
            discoveryId: id,
            loading: false,
            warnings: queryWarnings,
            queryVariables: variables,
            queryId,
          });
        });
    };
  },
  showConfirmRemoveField(id, field, shelf) {
    return {
      type: Const.Discover.SHOW_CONFIRM_REMOVE_FIELD,
      field,
      shelf,
      discoveryId: id,
      show: true,
    };
  },
  hideConfirmRemoveField() {
    return { type: Const.Discover.SHOW_CONFIRM_REMOVE_FIELD, show: false };
  },
  ignoreQueryError(id) {
    return { type: Const.Discover.IGNORE_VIZ_QUERY_ERROR, discoveryId: id };
  },
  showConfirmAddField(
    id: string,
    field: IAnyAttribute,
    shelf: ShelfName,
    breakingFields: IAnyAttribute[],
    insertionPosition?: number,
  ) {
    return {
      type: Const.Discover.SHOW_CONFIRM_ADD_FIELD,
      field,
      shelf,
      discoveryId: id,
      show: true,
      breakingFields,
      insertionPosition,
    };
  },
  hideConfirmAddField() {
    return { type: Const.Discover.SHOW_CONFIRM_ADD_FIELD, show: false };
  },
  updateVizName(id, newName) {
    return {
      type: Const.Discover.UPDATE_VIZ_NAME,
      discoveryId: id,
      newName,
    };
  },
  updateVizSetting(id, key, value) {
    return {
      type: Const.Discover.UPDATE_VIZ_SETTING,
      discoveryId: id,
      key,
      value,
    };
  },
  updateCustomColors(id, customColorsObj) {
    return dispatch => {
      const customColors = JSON.stringify(customColorsObj) || '{}';
      ColorManager.setCustomColors(id, customColorsObj);
      dispatch(
        Actions.updateVizSetting(id, VIZ_OPTION_IDS.customColors, customColors),
      );
    };
  },
  copyVizState(fromId, toId, toName) {
    return {
      type: Const.Discover.COPY_VIZ_STATE,
      fromId,
      toId,
      toName,
    };
  },
  copyUndoStack(fromId, toId, toName) {
    return {
      type: Const.Discover.COPY_UNDO_STACK,
      fromId,
      toId,
      toName,
    };
  },
  updateDatasetForDiscovery(discoveryId, dataset) {
    return {
      type: Const.Discover.UPDATE_DATASET_FOR_DISCOVERY,
      discoveryId,
      dataset,
    };
  },
  removeMissingFields(discoveryId, fieldsToRemove, filtersToRemove) {
    return {
      type: Const.Discover.REMOVE_MISSING_FIELDS,
      discoveryId,
      fieldsToRemove,
      filtersToRemove,
    };
  },
  setPivotSorting(discoveryId, sorting) {
    return {
      type: Const.Discover.SET_PIVOT_SORTING,
      discoveryId,
      sort: sorting,
    };
  },
  updateAggregation({ discoveryId, newAggregation, field, shelf }) {
    return {
      type: Const.Discover.UPDATE_AGGREGATION,
      discoveryId,
      shelfId: isObject(shelf) ? (shelf as IChartShelf).id : shelf,
      field: field.name,
      aggregation: newAggregation.name,
    };
  },
  openDatasetSettings(
    datasetId,
    isEditingDatasetSettings = false,
    isFinalizingAddDataset = false,
  ) {
    return {
      type: Const.Dataset.IS_EDITING_DATASET_SETTINGS,
      datasetId,
      isEditingDatasetSettings,
      isFinalizingAddDataset,
    };
  },
  designateRuntimeFilter({ discoveryId, field }) {
    return {
      type: Const.Discover.DESIGNATE_RUNTIME_FILTER,
      field,
      discoveryId,
    };
  },
  removeRuntimeFilter({ discoveryId, fieldName }) {
    return {
      type: Const.Discover.REMOVE_RUNTIME_FILTER,
      fieldName,
      discoveryId,
    };
  },
};

export default Actions;

// needed for Funnels to filter opportunities prior to vizQuery
export const getOpportunityClosed = ({ datasetID }) => {
  const variables = {
    id: datasetID,
    attributeNames: [
      'Opportunity Closed',
      'Stage',
      'OpportunityStageSortOrder',
      'OpportunityStage Sort Order',
    ],
    measures: [],
    filters: [
      {
        attributeName: 'Snapshot Date',
        operator: 'this',
        operands: ['1', 'DAYS', 'false'],
      },
    ],
    sorts: [],
    calcs: [
      {
        attributeName: 'OpportunityStageSortOrder',
        expression: '[OpportunityStage Sort Order]',
      },
    ],
    subtotals: [],
  };

  const queryOptions = {
    query: VizQueries.VizQuery,
    variables,
  };

  return client.query(queryOptions);
};

// possible modify variables (ex. Funnel vars) prior to vizQuery
export const postModifyVariables = async vars => {
  const {
    viz: { chartType },
  } = vars;
  if (chartType === 'funnel') {
    vars = await postModifyVariablesForFunnel(vars);
  }
  return vars;
};

// force a stage filter
export const postModifyVariablesForFunnel = async params => {
  const { viz, variables } = params;
  let opportunityClosedResult;
  const colName = 'Stage';

  const stageField = _.find(viz.layout.XAXIS, { name: colName });

  // make sure we have a stage to work on
  if (!stageField) {
    return params;
  }

  try {
    opportunityClosedResult = await getOpportunityClosed({
      datasetID: viz.dataset.id,
    });
  } catch (e) {
    console.error(e);
    return params;
  }
  const resultData = buildResultDataObjectFromQueryResult(
    opportunityClosedResult.data.executeQuery,
  );
  const resultDataSorted = _.sortBy(resultData.rows, [
    'OpportunityStage Sort Order',
  ]);
  const openStages = _(resultDataSorted)
    .filter(_.matches({ 'Opportunity Closed': 'false' }))
    .map('Stage')
    .value();
  const closedStages = _(resultDataSorted)
    .filter(_.matches({ 'Opportunity Closed': 'true' }))
    .map('Stage')
    .value();
  const allStages = resultDataSorted.map(row => row.Stage);

  let filtersObj = _.find(variables.filters, { attributeName: colName });
  if (!filtersObj) {
    filtersObj = {
      attributeName: colName,
      operator: 'In List',
      operands: [],
    };
    variables.filters.push(filtersObj);
  }
  let filtersList = filtersObj.operands;
  filtersList = filtersList.length <= 0 ? allStages : filtersList;

  const openStagesSelected = _.intersection(openStages, filtersList);
  const closedStagesSelected = _.intersection(closedStages, filtersList);
  const closedStagesRemoved = _.tail(closedStagesSelected);
  const closedStageSelected = _.head(closedStagesSelected);
  const combinedFilters = _.isEmpty(closedStageSelected)
    ? openStagesSelected
    : _.concat(openStagesSelected, closedStageSelected);

  filtersObj.operands = combinedFilters;

  return {
    ...params,
    variables,
    dispatch: autoChangeFunnelFilterAction({
      filterField: stageField,
      filterList: combinedFilters,
      closedStageSelected,
      closedStagesRemoved,
    }),
  };
};

export const autoChangeFunnelFilterAction =
  ({ filterField, filterList, closedStageSelected, closedStagesRemoved }) =>
  dispatch => {
    dispatch({
      type: Const.Discover.AUTO_HYDRATE_VIZ_FIELD_FILTER,
      filterField,
      filterList,
    });
    if (closedStagesRemoved.length > 0) {
      dispatch(
        MainActions.showToast({
          text: undefined,
          toastType: 'filtering-removal-message',
          closedStageSelected,
        }),
      );
    }
  };

export const buildResultDataObjectFromQueryResult = executeQuery => {
  const rows = executeQuery.results.map((item, index) => {
    return { ..._.zipObject(executeQuery.columnNames, item), index };
  });
  const columns = _.zipObject(
    executeQuery.columnNames,
    executeQuery.columnInfo,
  );
  return { columns, rows };
};
